import React from "react";
import { EditorBlock } from "draft-js";
import { Container, Sound } from "./HighlightedBlock.style";

interface Props {
  block: { key: string };
}

const HighlightedBlock: React.FC<Props> = (props) => {
  return (
    <Container id={props.block.key}>
      <EditorBlock {...props} />
      <Sound className="block-sound" id={`block-sound-${props.block.key}`} />
    </Container>
  );
};

export default HighlightedBlock;
