import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

interface SecureRouteProps extends RouteProps {
  isAuth: boolean;
}

const SecureRoute: React.FC<SecureRouteProps> = ({
  children,
  isAuth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={() => (isAuth ? children : <Redirect to="/login" />)}
    />
  );
};

export default SecureRoute;
