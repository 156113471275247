import React from "react";
import Button from "antd/es/button";
import authStore from "../../store/auth.store";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import Path from "../../enum/Path";

interface Props {}

const Login: React.FC<Props> = observer(() => {
  const { authorized } = authStore;
  if (authorized) {
    return <Redirect to={Path.HOME} />;
  }

  return (
    <div>
      <Button
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook`)
        }
      >
        FB
      </Button>
    </div>
  );
});

export default Login;
