import React from "react";
import PageContainer from "../../compoenent/layout/PageContainer";

interface Props {}

const Home: React.FC<Props> = () => {
  return (
    <PageContainer title="Home page">
      <p>It's a home page</p>
    </PageContainer>
  );
};

export default Home;
