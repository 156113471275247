import styled from "styled-components";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

export const Container = styled.div`
  height: 100%;
`;

export const FullHeightStyledRow = styled(Row)`
  height: 85%;
`;

export const FullHeightStyledCol = styled(Col)`
  height: 100%;
`;

export const BookWrapper = styled(Col)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px;
`;

export const EditorStyled = styled.div`
  border: 1px solid black;
  text-align: justify;
  padding: 1rem;
  text-indent: 2rem;
  background: rgba(134, 183, 176, 0.27);
  border-radius: 1rem;
`;
