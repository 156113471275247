import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SecureRoute from "../compoenent/misc/SecureRoute";
import Path from "../enum/Path";
import Home from "./home";
import Terms from "./terms";
import { observer } from "mobx-react-lite";
import Loader from "../compoenent/misc/Loader";
import authStore from "../store/auth.store";
import Login from "./login";
import Studio from "./studio";

const App = observer(() => {
  const { authChecking, checkAuth, authorized, initialized } = authStore;

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authChecking || !initialized) {
    return <Loader />;
  }
  return (
    <Switch>
      <Route path={Path.LOGIN} exact>
        <Login />
      </Route>
      <Route path={Path.TERMS} exact>
        <Terms />
      </Route>
      <SecureRoute exact path={Path.HOME} isAuth={authorized}>
        <Home />
      </SecureRoute>
      <SecureRoute path={Path.STUDIO} isAuth={authorized}>
        <Studio />
      </SecureRoute>
    </Switch>
  );
});

export default App;
