import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import studioStore from "../../studio.store";
import { TrackEdited } from "../../../../type/entity/Track";
import List from "antd/es/list";
import { PlusButton, TrackContainer, TrackSelect } from "./TrackList.style";
import { Select } from "antd";
import Sound from "../../../../type/entity/Sound";

const { Option } = Select;

interface Props {
  activeKey?: string;
  activeTrack?: number;
  onSelectTrack: (index: number | undefined) => void;
  onAddTrack: () => void;
  onChangeSound: (index: number, soundId: Sound["id"]) => void;
  onSetActiveTrack: (index?: number) => void;
  selectedIndex: number | undefined;
  tracks: TrackEdited[];
}

const TrackList: React.FC<Props> = observer(
  ({
    activeKey,
    activeTrack,
    onSelectTrack,
    onAddTrack,
    onChangeSound,
    onSetActiveTrack,
    selectedIndex,
    tracks,
  }) => {
    const { data } = studioStore.soundListData;

    const usedSoundIds: Set<Sound["id"]> = useMemo(
      () =>
        new Set(
          tracks.reduce<Sound["id"][]>(
            (result, track) => [
              ...result,
              ...(track.soundId ? [track.soundId] : []),
            ],
            []
          )
        ),
      [tracks]
    );

    // const handleToggleActiveKey = useCallback(
    //   (index) => {
    //     if (activeKey) {
    //       onUpdateTracks([
    //         ...tracks.slice(0, index),
    //         {
    //           ...tracks[index],
    //           keys: tracks[index].keys.includes(activeKey)
    //             ? tracks[index].keys.filter((key) => key !== activeKey)
    //             : [...tracks[index].keys, activeKey],
    //         },
    //         ...tracks.slice(index + 1),
    //       ]);
    //     }
    //   },
    //   [activeKey, onUpdateTracks, tracks]
    // );

    useEffect(() => {
      studioStore.soundListFetch();
    }, []);

    return (
      <div>
        <List.Item
          actions={[
            ...(tracks.length < 3 ? [<PlusButton onClick={onAddTrack} />] : []),
          ]}
        >
          <List.Item.Meta
            title="Tracks:"
            description="Press plus button to add track"
          />
        </List.Item>
        {tracks.map((track, i) => (
          <TrackContainer
            active={activeTrack === i}
            onClick={() => onSetActiveTrack(activeTrack === i ? undefined : i)}
          >
            <TrackSelect
              placeholder="Tags Mode"
              onChange={(value) => onChangeSound(i, value as Sound["id"])}
              value={track.soundId}
            >
              {data?.map((sound) => (
                <Option
                  disabled={
                    usedSoundIds.has(sound.id) && sound.id !== track.soundId
                  }
                  value={sound.id}
                >
                  {sound.title}
                </Option>
              ))}
            </TrackSelect>
            {activeKey && (
              <i onClick={() => {}}>
                {track.keys.includes(activeKey) ? "Detach" : "Attach"}
              </i>
            )}
          </TrackContainer>
        ))}
      </div>
    );
  }
);

export default TrackList;
