import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { convertFromRaw, convertToRaw, Editor, EditorState } from "draft-js";
import Button from "antd/es/button";
import Input from "antd/es/input";
import studioStore from "../studio.store";
import Col from "antd/lib/col";
import TrackList from "./TrackList";
import {
  BookWrapper,
  Container,
  EditorStyled,
  FullHeightStyledCol,
  FullHeightStyledRow,
} from "./Book.style";
import HighlightedBlock from "./HighlightedBlock";
import { TrackEdited } from "../../../type/entity/Track";
import Sound from "../../../type/entity/Sound";

const blockRenderer = () => ({
  component: HighlightedBlock,
  editable: true,
});

interface Props {}

const BookList: React.FC<Props> = observer(() => {
  const { id } = useParams<{ id?: string }>();
  const [editor, setEditor] = useState();
  const [title, setTitle] = useState<string>("");
  // const [keys, setKeys] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [activeTrack, setActiveTrack] = useState<number | undefined>();
  const { data } = studioStore.bookData;
  const [tracks, setTracks] = useState<TrackEdited[]>(data?.Tracks ?? []);
  const [selectedTrackIndex, setSelectedTrackIndex] = useState<
    number | undefined
  >();

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(data.text)))
      );
    }
  }, [data, setEditorState, setTitle]);

  useEffect(() => {
    if (id) {
      studioStore.bookFetch(parseInt(id));
    } else {
      setTitle("");
      setEditorState(EditorState.createEmpty());
    }
  }, [id]);

  const handleAddTrack = useCallback(() => {
    setTracks([
      ...tracks,
      {
        keys: [],
        comment: "",
      },
    ]);
  }, [setTracks, tracks]);

  const handleChangeSound = useCallback(
    (index, soundId: Sound["id"]) => {
      setTracks([
        ...tracks.slice(0, index),
        { ...tracks[index], soundId },
        ...tracks.slice(index + 1),
      ]);
    },
    [tracks, setTracks]
  );

  useEffect(() => {
    const elements = document.getElementsByClassName("block-sound");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.classList.remove("sounded-block");
    }
    if (activeTrack) {
      const track = tracks[activeTrack];
      track.keys.forEach((key) => {
        document
          .getElementById(`block-sound-${key}`)
          ?.classList.add("sounded-block");
      });
    }
  }, [activeTrack, tracks]);

  useEffect(() => {
    const currentKey = editorState.getSelection().getStartKey();
    if (activeKey !== currentKey) {
      if (activeKey) {
        document
          .getElementById(activeKey)
          ?.classList.remove("highlighted-block");
      }
      document.getElementById(currentKey)?.classList.add("highlighted-block");
      setActiveKey(currentKey);
    }
    const content = convertToRaw(editorState.getCurrentContent());
    if (content) {
      // setKeys(content.blocks.map(({ key }) => key));
    }
  }, [activeKey, editorState, setActiveKey]);

  const focusEditor = useCallback(() => {
    // @ts-ignore
    editor.focus();
  }, [editor]);

  const handleSave = useCallback(() => {
    const text = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    if (id) {
      studioStore.updateBook(parseInt(id, 10), title, text, []);
    } else {
      studioStore.createBook(title, text, []);
    }
  }, [editorState, id, title]);

  return (
    <Container>
      <Button onClick={handleSave}>{id ? "Save changes" : "Create"}</Button>
      <Input
        placeholder="Title"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />
      <FullHeightStyledRow gutter={24}>
        <FullHeightStyledCol span={18}>
          <BookWrapper>
            <EditorStyled onClick={focusEditor}>
              <Editor
                editorState={editorState}
                onChange={setEditorState}
                blockRendererFn={blockRenderer}
                // @ts-ignore
                ref={setEditor}
              />
            </EditorStyled>
          </BookWrapper>
        </FullHeightStyledCol>
        <Col span={6}>
          <TrackList
            activeKey={activeKey}
            activeTrack={activeTrack}
            onAddTrack={handleAddTrack}
            onChangeSound={handleChangeSound}
            onSelectTrack={setSelectedTrackIndex}
            selectedIndex={selectedTrackIndex}
            onSetActiveTrack={setActiveTrack}
            tracks={tracks}
          />
        </Col>
      </FullHeightStyledRow>
    </Container>
  );
});

export default BookList;
