import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
`;

export const Sound = styled.div`
  border: 0.5px dashed grey;
  border-radius: 4px;
  margin: 2px 0;
  min-width: 12px;
`;
