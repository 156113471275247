import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Loader from "../../../compoenent/misc/Loader";
import studioStore from "../studio.store";
import { Link } from "react-router-dom";
import Path from "../../../enum/Path";
import List from "antd/es/list";
import Button from "antd/es/button";
import Typography from "antd/es/typography";

const { Title } = Typography;

interface Props {}

const BookList: React.FC<Props> = observer(() => {
  const { data, loading } = studioStore.bookListData;
  useEffect(() => {
    studioStore.bookListFetch();
  }, []);

  if (loading || !data) {
    return <Loader />;
  }
  return (
    <div>
      <Title>My books</Title>
      <Link to={`${Path.STUDIO}/book`}>
        <Button type="primary">Create new</Button>
      </Link>
      <List
        dataSource={data}
        renderItem={(book) => (
          <List.Item
            actions={[<Link to={`${Path.STUDIO}/book/${book.id}`}>Edit</Link>]}
          >
            <List.Item.Meta title={book.title} description={book.updatedAt} />
          </List.Item>
        )}
      />
    </div>
  );
});

export default BookList;
