import React, { useCallback } from "react";
import Button from "antd/es/button";
import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import Path from "../../enum/Path";
import { observer } from "mobx-react-lite";
import authStore from "../../store/auth.store";

interface Props {
  children: React.ReactNode;
  title: string;
}

const PageContainer: React.FC<Props> = observer(({ children, title }) => {
  const history = useHistory();
  const { currentUser } = authStore;
  const handleLogoClick = useCallback(() => {
    history.push(Path.HOME);
  }, [history]);

  const handleStudioClick = useCallback(() => {
    history.push(Path.STUDIO);
  }, [history]);

  return (
    <PageHeader
      className="site-page-header-responsive"
      title={<div onClick={handleLogoClick}>Sound Tale</div>}
      subTitle={title}
      extra={[
        <Button key="3" onClick={handleStudioClick}>
          Studio
        </Button>,
        <Button key="1" type="primary">
          {currentUser?.firstName} {currentUser?.lastName}
        </Button>,
      ]}
    >
      {children}
    </PageHeader>
  );
});

export default PageContainer;
