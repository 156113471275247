import { action, makeObservable, observable } from "mobx";
import Book from "../../type/entity/Book";
import bookService from "../../service/network/book.service";
import NetworkData from "../../type/NetworkData";
import initializeNetworkData from "../../util/initializeNetworkData";
import Track, { TrackRaw } from "../../type/entity/Track";
import Sound from "../../type/entity/Sound";
import soundService from "../../service/network/sound.service";

export class StudioStore {
  bookListData: NetworkData<Book[]> = initializeNetworkData<Book[]>([]);
  bookListUpdater(networkData: NetworkData<Book[]>) {
    this.bookListData = networkData;
  }
  bookListFetch() {
    bookService.fetchNetworkData<Book[]>(this.bookListUpdater, [], "my");
  }

  bookData: NetworkData<Book> = initializeNetworkData<Book>();
  bookUpdater(networkData: NetworkData<Book<TrackRaw>>) {
    this.bookData = {
      ...networkData,
      data: networkData.data
        ? {
            ...networkData.data,
            Tracks: networkData.data.Tracks.map((track) => ({
              ...track,
              keys: track.keys.split(","),
            })),
          }
        : undefined,
    };
  }
  bookFetch(id: Book["id"]) {
    bookService.fetchNetworkData<Book<TrackRaw>>(
      this.bookUpdater,
      undefined,
      id.toString()
    );
  }

  soundListData: NetworkData<Sound[]> = initializeNetworkData<Sound[]>([]);
  soundListUpdater(networkData: NetworkData<Sound[]>) {
    this.soundListData = networkData;
  }
  soundListFetch() {
    soundService.fetchNetworkData<Sound[]>(this.soundListUpdater, []);
  }

  createBook(title: string, text: string, tracks: Track[]) {
    bookService.post({ title, text }).then(this.bookListFetch);
  }

  updateBook(id: Book["id"], title: string, text: string, tracks: Track[]) {
    bookService
      .put({ title, text, tracks }, id.toString())
      .then(this.bookListFetch);
  }

  constructor() {
    makeObservable(this, {
      bookListData: observable,
      bookListUpdater: action.bound,
      bookData: observable,
      bookUpdater: action.bound,
      soundListData: observable,
      soundListUpdater: action.bound,
    });
  }
}

const studioStore = new StudioStore();

export default studioStore;
