import React from "react";
import PageContainer from "../../compoenent/layout/PageContainer";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BookList from "./component/BookList";
import Book from "./component/Book";

interface Props {}

const Studio: React.FC<Props> = () => {
  const { path } = useRouteMatch();

  return (
    <PageContainer title="Creative Workshop">
      <Switch>
        <Route path={`${path}/book/:id?`}>
          <Book />
        </Route>
        <Route path="" exact>
          <BookList />
        </Route>
      </Switch>
    </PageContainer>
  );
};

export default Studio;
