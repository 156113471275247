import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { RED_COLOR } from "../../../../style/consts";
import Select from "antd/es/select";

export const PlusButton = styled(PlusOutlined)`
  color: ${RED_COLOR};
`;

export const TrackContainer = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 10px;
  border: 1px dashed gray;
  height: 100px;
  background-color: ${(props) => (props.active ? "red" : "inherit")};
`;

export const TrackSelect = styled(Select)`
  width: 100%;
`;
